.shimmerwrapper {
  width: 100%;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 6px 6px #ebe7e745;
  border-radius: 2px;
}

.shimmerwrapper .shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 300px;
  display: inline-block;
  position: relative;

  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.shimmerwrapper .profile {
  height: 104px;
  width: 100px;
}

.shimmerwrapper .text {
  display: inline-flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 15px;
  vertical-align: top;
  width: calc(100% - 150px);
}

.shimmerwrapper span {
  height: 10px;
  margin-top: 10px;
  width: 100%;
}

.shimmerwrapper .photo {
  display: block !important;
  width: 100%;
  height: 300px;
  margin-top: 15px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
