@font-face {
  font-family: "Google Sans";
  src: url("../fonts/GoogleSans-Regular.eot");
  src: url("../fonts/GoogleSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GoogleSans-Regular.woff") format("woff"),
    url("../fonts/GoogleSans-Regular.ttf") format("truetype"),
    url("../fonts/GoogleSans-Regular.svg#GoogleSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Google Sans" !important;
  background: #f7f7f7;
}

a:hover,
a:focus {
  color: #6c63ff;
  text-decoration: none;
}

.homebanner {
  background-color: #ffffff;
  background-image: url("../img/header-bg1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 0 0 0;
  min-height: 100vh;
}

.navbar-default {
  background-color: transparent;
  border: 0;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.homebanner h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 40px;
}

@media only screen and (min-width: 1200px) {
  .homebanner h4 {
    font-size: 45px;
    color: #ffffff;
    font-weight: bold;
    margin-top: 20px;
    line-height: 58px;
  }
}

.homebanner h4 {
  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
  line-height: 45px;
}

.homebanner p {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  max-width: 400px;
}

.homebanner a button {
  outline: none !important;
}

.logincard {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.logincard .logoimg {
  display: inline-block !important;
  position: relative;
  top: 70px;
}

/* .logincard .ant-card-body{
  padding: 0;
} */

.logincard .divbg {
  height: 100vh;
  /* background-color: #00215B; */
  background-image: url("../img/login.jpg");
  background-size: cover;
  min-height: 100vh;
}

.logincard .divbg1 {
  height: 100vh;
  /* background-color: #00215B; */
  background-image: url("../img/signup.jpg");
  background-size: cover;
  min-height: 100vh;
}

.logincard .centerdiv {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 500px;
}

.logincard h2 {
  font-size: 36px;
  font-weight: bolder;
  color: #fff;
  margin-bottom: 15px;
  line-height: 45px;
}

.logincard p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 15px;
}

.logincard .bgright {
  background-color: #fff;
  height: 100vh;
}

.ant-card-bordered {
  border: 0;
}

.loginBtn {
  font-size: 14px;
  color: #333;
  padding: 8px 20px;
  cursor: pointer;
  margin: 20px;
  border-radius: 4px;
  text-align: center;
  border: none;
  height: auto;
  background-size: 300% 100%;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.loginBtn:hover,
.loginBtn:focus {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  outline: none !important;
}

.loginBtn.color-1 {
  background-image: linear-gradient(to right,
      #ffcb52,
      #f3a52f,
      #f3a52f,
      #ffcb52);
  box-shadow: 0 3px 6px 0 #ffcb52bf;
}

.hero {
  background-color: #ffffff;
  background-image: url("../img/pattern1.svg");
  color: #212121;
  text-align: center;
  padding: 70px 0;
  background-position: -115px 28px;
  background-size: 200px;
  background-repeat: no-repeat;
}

.hero1 {
  background-color: #1141e1;
  background-image: url("../img/pattern3.svg");
  text-align: center;
  padding: 70px 0;
  background-position: 5px 108px;
  background-size: 100px;
  background-repeat: no-repeat;
}

.hero h4 {
  color: #1141e1;
  font-size: 30px;
  font-weight: 700;
  max-width: 700px;
  margin: auto;
  margin-bottom: 20px;
  line-height: 1.25;
}

.hero p {
  color: #585858;
  max-width: 600px;
  margin: auto;
  margin-bottom: 25px;
  line-height: 1.5;
  font-size: 16px;
}

.featureimg {
  height: 150px;
  margin: auto;
  margin-bottom: 30px;
}

.hero .ant-card h6 {
  color: #1141e1;
  margin-bottom: 20px;
  font-size: 18px;
  /* font-family: "Covered By Your Grace", cursive; */
}

.hero .ant-card p {
  color: #6f6f6f;
  margin-bottom: 0px;
  font-size: 14px;
  min-height: 100px;
}

.hero .row {
  margin-bottom: 90px;
}

.follow {
  color: #fff;
  font-weight: 600;
}

div .logo {
  width: 120px;
  float: left;
}

.ant-layout-header {
  height: 52px;
  padding: 0 10px 0 50px;
  left: 0px;
  line-height: 50px;
  background: #1c41ce;
  box-shadow: 0 6px 12px 2px #00000020;
}

.ant-menu-horizontal>.ant-menu-item>a {
  line-height: 50px !important;
}

.ant-menu-dark.ant-menu-horizontal {
  line-height: 50px !important;
}

.interesttext {
  color: #1141e1;
  font-weight: 600;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #1c41ce !important;
}

.undrawimg {
  max-width: 300px;
  margin: auto;
}

.logo-pic {
  height: 20px;
}

.ant-card {
  box-shadow: 0 6px 6px #ebe7e745;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark,
.ant-menu-item-selected {
  background-color: #1c41ce;
}

.ant-menu-item-selected .dripicons-location:before {
  color: #1c41ce;
}

.ant-menu a {
  line-height: 43px !important;
}

.ant-menu-item-selected a {
  color: #1c41ce !important;
  font-weight: 700;
}

.ant-menu-item-selected a i {
  color: #1c41ce !important;
  font-weight: 700;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #fff;
  background: #1c41ce;
}

.ant-form-item-control {
  position: relative;
  line-height: 0;
  zoom: 1;
}

.loginHead {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #1c41ce;
}

.headline {
  border-width: 20%;
  border-bottom: 3px solid #f3a52f;
  width: 6%;
  margin-bottom: 20px;
}

.headline1 {
  border-width: 20%;
  border-bottom: 3px solid #f3a52f;
  width: 2%;
  margin-bottom: 20px;
}

.ant-form label {
  font-size: 14px;
  margin-bottom: 15px;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #333;
  background-color: #fff;
  border-color: transparent;
}

.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  top: 30px !important;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
  margin-bottom: 15px;
}

.forgottext {
  float: right;
  margin-top: 15px;
  color: #1c41ce;
}

a {
  color: #6c63ff;
}

.footer {
  padding: 80px 0 40px 0;
  background-color: #fff;
  color: #ffffff;
  background-image: url("../img/footer-bg.svg");
  background-size: cover;
  min-height: 300px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer {
    background-position: -92px;
  }
}

.footer ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 30px;
}

.footer li {
  color: #ffcb52;
  line-height: 30px;
}

.footer li a {
  color: #ffcb52 !important;
}

.loginscreen {
  background-image: url("../img/loginscreen.png");
  background-size: cover;
  min-height: 100vh;
}

.loginscreen h2 {
  color: #f4a933;
  font-size: 42px;
  font-weight: 600;
}

.loginscreen p {
  color: #fff;
  font-size: 16px;
  line-height: 32px;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a {
  color: #fff;
}

.ant-form-item .has-error {
  margin-bottom: 10px !important;
}

/* .ant-form-item {
  margin-bottom: 30px;
} */

.footer-top {
  margin-top: 100px;
}

@media (min-width: 1600px) and (max-width: 2340px) {
  .footer-top {
    margin-top: 140px;
  }
}

.error {
  color: #f5222d;
}

.ant-input:focus,
.ant-input:hover {
  border-color: #1c41ce;
  -webkit-box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2);
}

.ant-select-selection:focus,
.ant-select-selection:hover {
  border-color: #1c41ce;
  -webkit-box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2);
}

.registertext {
  color: #1c41ce;
  text-decoration: underline;
}

.userbg {
  background: #fff;
  /* background-image: url("../img/d-cover.jpg");
  background-size: cover; */
  min-height: 300px;
}

.userbg1 {
  background: #1c41ce;
  /* background-image: url("../img/d-cover.jpg");
  background-size: cover; */
  min-height: 300px;
}

.topup {
  position: relative;
  top: -140px;
}

.imageup {
  position: relative;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  border-radius: 4px;
}

.profile-detail .ant-tabs-tabpane {
  background-color: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  border-radius: 4px;
  padding: 15px;
}

.profile-detail .ant-tabs-tabpane h5 {
  margin-bottom: 20px;
}

.profile-detail .userbox {
  padding: 20px 15px 15px 15px;
  border: 1px solid #efefef;
  position: relative;
  border-radius: 4px;
  margin-bottom: 10px;
}

.profile-detail .userbox .userphoto {
  display: block;
}

.topregister {
  font-size: 18px;
  color: #fff;
}

.ant-tabs-ink-bar {
  background: #1c41ce;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-tab:hover {
  color: #1c41ce;
  font-weight: 500;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 30px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.profilemenu span {
  margin-right: 5px;
}

.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  -ms-transform-origin: 0 center;
  transform-origin: 0 center;
}

.ant-avatar-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
}

.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1c41ce;
}

@media (min-width: 320px) and (max-width: 767px) {
  .avatar-uploader>.ant-upload {
    width: 100%;
    height: 100%;
  }
}

.loginBtn.cancel {
  background: #333;
  color: #fff !important;
  box-shadow: 0 2px 2px 0 #3333334f;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.time {
  /* float: right; */
  color: #b6b1b1;
  font-size: 12px;
}

.imagecontainer {
  position: relative;
  width: 100%;
  max-height: 150px;
  cursor: pointer;
}

.imagecontainer:hover {
  background-color: #f5f5f5;
}

.imgbanner {
  max-width: 100%;
  max-height: 150px;
}

.imagecontainer1 {
  position: relative;
  width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.imagecontainer1:hover {
  background-color: #f5f5f5;
}

.iconvisible {
  position: relative;
  width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.iconvisible:hover {
  background-color: #f5f5f5;
}

.imgbanner1 {
  max-width: 100%;
  max-height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: 0.5s ease;
}

.imagecontainer:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.imagecontainer1:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.iconvisible:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.button {
  position: absolute;
  width: 100%;
  right: 0;
  top: 3px;
  opacity: 0;
  transition: 0.5s ease;
}

.button .delete {
  position: absolute;
  right: 3px;
  background-color: #fff;
  color: #1c41ce;
  width: 40px;
  z-index: 1;
}

.badge-success {
  color: #31b347;
}

.badge-danger {
  color: #cc2132;
}

.delete i {
  text-align: center;
  position: relative;
  right: 3px;
}

.imagecontainer:hover .button {
  opacity: 1;
}

.imagecontainer1:hover .button {
  opacity: 1;
}

.done {
  text-decoration: line-through;
  color: #ccc;
}

.done textarea {
  text-decoration: none !important;
}

.empty {
  color: darkgray;
}

.media-head button {
  font-size: 18px;
}

.btn-toggle {
  background-color: white;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.media-head button {
  font-size: 18px;
}

.btn-toggle {
  background-color: white;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.topalign {
  margin-top: 10px !important;
}

.btn-tick:focus {
  outline: none !important;
}

.btn-tick:active {
  box-shadow: none !important;
}

.divalign {
  margin-bottom: 15px;
  margin-top: 35px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  padding-bottom: 10px;
  margin-bottom: 1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-align-left {
  /* position: absolute;
  left: 0;
  margin-left: 45px; */
  margin-left: 10px;
  margin-right: 10px;
  word-break: break-all;
  width: 723px;
  display: inline-block;
}

.list-align-right {
  display: inline-block;
  /* position: absolute;
  right: 0;
  margin-right: 15px; */
}

.list-group {
  padding: 20px;
}

.done {
  text-decoration: line-through;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #1c41ce;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1c41ce;
  border-color: #1c41ce;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #1c41ce;
  border-color: #1c41ce;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.logstab .ant-tabs-nav .ant-tabs-tab-active,
.logstab .ant-tabs-tab:hover {
  font-weight: 500;
  height: 40px;
  color: #1c41ce !important;
  background: transparent !important;
  border-color: #fff;
  border-bottom: 1px solid #fff !important;
  background: #fff;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1c41ce;
}

.ant-pagination-item-active a {
  color: #1c41ce;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1c41ce;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1c41ce;
}

.ant-select-selection--multiple>ul>li,
.ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
  height: 35px;
}

.list-head {
  /* margin-top: 15px; */
  margin-bottom: 15px;
}

.list-head-text {
  font-size: 16px;
  font-weight: 600;
}

.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
  /* text-align: center; */
}

.logstab .ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
  text-align: center;
}

/* .css-18c45ln {
    background-color: rgba(28, 29, 29, 0.87) !important;
}

.css-18c45ln, .css-58ydo5 div svg path ,  
.css-qygj7z div svg path, .css-qygj7z div svg path,
.css-1jgzeom div svg path, .css-28hino div svg path  {
    fill: rgb(255, 255, 255) !important;
} */

.breaklogs {
  width: 100%;
  word-break: break-all;
}

.ant-calendar-selected-day .ant-calendar-date {
  background: #d6ddf9;
}

.ant-calendar-today .ant-calendar-date {
  color: #1c41ce;
  font-weight: bold;
  border-color: #1c41ce;
}

/* Feeds */

.alllogs {
  /* height: calc(100vh - 64px);
  overflow-y: scroll; */
  margin-top: -15px;
  padding-top: 15px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.alllogs::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.alllogs {
  -ms-overflow-style: none;
}

.ant-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.feedsmemlist .ant-card-body {
  padding: 20px 10px;
}

.feedsmemlist h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-left: 10px;
}

.memberlist {
  margin-bottom: 10px;
}

.memberlist li {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: 0.25s all ease-in;
}

.memberlist li:hover {
  background-color: #f5f5f5;
}

.memberlist li .userphoto {
  display: inline-block;
  vertical-align: top;
}

.memberlist li .userinfo {
  display: inline-block;
  color: #3a3a3a;
}

.memberlist li .userinfo .memcity {
  font-size: 12px;
  color: #817f7f;
}

.feedsmemlist a.viewmore {
  font-size: 12px;
  color: #817f7f;
  padding: 12px 10px;
}

.feedsmemlist a.viewmore:hover {
  font-size: 12px;
  color: #1c41ce;
  padding: 12px 10px;
}

.postuserinfo {
  display: flex;
  align-items: center;
}

.postuserinfo .userphoto {
  display: inline-block;
  vertical-align: top;
}

.postuserinfo .userinfo {
  display: inline-block;
  color: #3a3a3a;
}

.feedscard .ant-card-body {
  padding: 0;
  zoom: 1;
}

.feedscard .ant-card-body p {
  margin-bottom: 10px;
}

.feedscard .ant-card-extra .ant-dropdown-link {
  border-radius: 4px;
  transition: all .25s ease-in;
  margin-top: -8px;
  padding: 8px 5px;
  height: 35px;
}

.feedscard .ant-card-extra .ant-dropdown-link:hover {
  background-color: #f9f9f9;
}

.postuserinfo .userinfo .memcity {
  font-size: 12px;
  color: #817f7f;
}

.feedsclip {
  width: 100%;
  max-width: 1336px;
}

.postbutton h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* For demo */
.ant-carousel .slick-slide {
  text-align: center;
  height: 100%;
  max-height: 90vh;
  line-height: 160px;
  /* background: #000; */
  overflow: hidden;
  display: flex;
  align-items: center;
}

.ant-carousel .slick-slide .img {
  margin: 0 auto;
  /* height: 100%; */
  max-height: 100vh;
  max-width: 100%;
  border: 1px solid #ffffff0f;
}

.ant-carousel .slick-slide .img1 {
  margin: 0 auto;
  /* height: 100%; */
  max-height: 100vh;
  /* height: 600px; */
  max-width: 100%;
  border: 1px solid #ffffff0f;
  margin-bottom: 10px;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  box-shadow: 0px 2px 5px #000;
  border: 0;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  /* background-color: black; */
}

.slick-slide img {
  margin: auto;
}

.postaction {
  padding: 10px 0 0px 0;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  border-top: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postactionbtn {
  display: block;
  margin: 0px 5px;
  border-radius: 2px;
  padding: 0px 5px;
}

.postactionbtn:hover {
  background-color: #f9f9f9;
}

.postactionbtn .ant-btn {
  padding: 0 5px 5px 8px;
  height: auto;
}

.postactionbtn .ant-btn i {
  line-height: 0;
}

.postaction1 {
  /* padding: 10px 0 0px 0; */
  margin-top: 5px;
  /* margin-bottom: 0px; */
}

.postaction .ant-btn-link {
  color: #333333;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.postaction .Like:hover,
.postaction .Liked {
  color: #31b347;
  /* background-color: #2e68d308; */
}

.postaction .DisLike:hover,
.postaction .DisLiked {
  color: #cc2132;
  /* background-color: #d38b2e08; */
}

.actioncount {
  padding: 2px 5px;
  display: inline-block;
}

.actioncount:hover .badge-success {
  text-decoration: underline;
}

.actioncount span {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  position: relative;
}

.carouselmodal .ant-modal-close-icon {
  box-shadow: none;
  color: #ffffff;
  position: absolute;
  right: -80px;
  top: 5px;
}

.carouselmodal .ant-modal-body {
  padding: 10px;
  background-color: #fff;
}

.postaction .icon {
  display: inline-block;
}

.m-b-0 {
  margin-bottom: 0;
}

.postportfoliotitle {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 600;
}

.sticky {
  position: sticky;
  top: 80px;
}

.portfolio .ant-card-body {
  padding-bottom: 10px;
}

img.portfoliouserimg {
  /* max-width: 150px; */
  max-height: 150px;
  margin-bottom: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.postimg {
  padding: 0;
}

.portfolioprocard {
  margin-bottom: 20px;
}

.daycard .ant-card-body {
  padding: 10px !important;
  margin-bottom: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.short-text {
  margin-bottom: 10px;
  white-space: pre-line;
}

.card-dark {
  background-color: #233944;
  margin: 20px 0;
}

.card-dark h5 {
  color: #fff;
  margin-bottom: 15px;
}

.readMoreText {
  color: #1c41ce !important;
  cursor: pointer;
}

.reactionsmodal .ant-modal-body {
  padding: 0;
}

.reactionsmodal .ant-tabs-content {
  padding: 0 20px 20px 20px;
  max-height: 60vh;
  overflow-y: scroll;
}

.reactionuserlist {
  list-style-type: none;
}

.reactionuserlist a li {
  border-bottom: 1px solid #e8e8e8;
}

.reactionuserlist a:last-child li {
  border-bottom: 1px solid transparent;
}

.reactionuserlist li:hover {
  background-color: #f5f5f5;
}

.reactionuserlist li div {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
}

.reactionuserlist li div.icon {
  display: block;
  position: absolute;
  right: 0;
  bottom: 5px;
  padding: 3px 2px;
  font-size: 8px;
  color: #fff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  text-align: center;
  z-index: 1;
}

.reactionuserlist li div.Liked {
  background-color: #6c63ff;
}

.reactionuserlist li div.DisLiked {
  background-color: #ff9c1b;
}

.reactionuserlist li .userinfo {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.reactionuserlist li span {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}

.reactionuserlist li .userphoto {
  position: relative;
}

.reactionuserlist li .DisLiked {
  color: #ff9c1b;
  font-size: 13px;
  font-weight: 400;
}

.reactionuserlist li .Liked {
  color: #2e68d3;
  font-size: 13px;
  font-weight: 400;
}

/* Feeds */

/* people */
.userslist li {
  padding: 10px;
  position: relative;
}

.userslist li .userbox {
  padding: 35px 15px 15px 15px;
  border: 1px solid #efefef;
  position: relative;
}

.userslist li .headerbox {
  height: 70px;
  background-color: #e3e9ff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.userslist li .userphoto .ant-avatar {
  margin-bottom: 15px;
}

.userslist li .userphoto .ant-avatar-image {
  background-color: #fff;
}

.userbox span.userinfo {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: #1c41ce !important;
}

.userbox span.memcity {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 400;
  display: block;
  color: #bbbbbb;
}

.ant-btn.follow {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  font-weight: 400;
}

.ant-card-head {
  padding: 0 15px;
}

.ant-card-head-title {
  padding: 5px 0;
}

.ant-btn.follow:hover {
  color: #1c41ce;
  background-color: #fff;
  border-color: #1c41ce;
  font-weight: 400;
}

.ant-btn.following {
  color: #1c41ce;
  background-color: #e2e8ff;
  border-color: #e2e8ff;
  font-weight: 400;
}

.ant-btn.userfollwg {
  color: #333;
  background-image: linear-gradient(to right,
      #ffcb52,
      #f3a52f,
      #f3a52f,
      #ffcb52);
  box-shadow: 0 3px 3px 0 #ffcb5260;
  border: 0;
  font-weight: 400;
  width: 120px;
  border-radius: 4px;
}

.ant-btn.userfoll {
  color: #ffffff;
  background-color: #1c41ce;
  border-color: #1c41ce;
  font-weight: 400;
  width: 120px;
  box-shadow: 2px 2px 6px #1c41ce96;
}

.readdiv div {
  width: calc(100% - 95px);
  /* display: inline-block; */
  vertical-align: top;
}

.carousel .thumbs-wrapper {
  display: none;
  margin: 0 !important;
}

.ant-card-body .readdiv:last-child {
  margin-bottom: 0 !important;
}

.readdiv .breakdes1 {
  display: inline-block;
  width: 75px;
  text-align: right;
  vertical-align: top;
}

.nofeeds {
  width: 100%;
  background-color: #fff;
  padding-top: 40px;
  text-align: center;
}

.imagecontainer1 {
  position: relative;
  /* padding-top: 5px; */
  /* width: 100%; */
  max-height: 100%;
  cursor: pointer;
}

.nofeeds img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
}

.ant-btn.editporfile {
  color: #ffffff;
  background-color: #1c41ce;
  border-color: #1c41ce;
  font-weight: 400;
  max-width: 200px;
}

.breakdes2 {
  display: inline;
  text-align: right;
  position: absolute;
  right: 10px;
  margin-top: 2px;
  /* top: 7px; */
}

.ant-btn.following:hover {
  color: #1c41ce;
  background-color: #e2e8ff;
  border-color: #1c41ce;
  font-weight: 400;
}

.error-class {
  border: 1px solid red !important;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #f3a52f;
  opacity: 1;
}

.limitexceed {
  color: #1c41ce;
  font-size: 16px;
  text-align: center;
}

.breakdes {
  display: inline;
  text-align: right;
  position: absolute;
  right: 10px;
  margin-top: 2px;
  top: 7px;
}

.stickycard {
  position: sticky;
  top: 0;
}

.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.infinite-scroll-component {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.profileimgage {
  margin-bottom: 20px;
}

.userimage {
  margin-bottom: 20px;
}

.profileimgage img {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  border-radius: 5px;
}

.userimage img {
  max-height: 200px;
  width: 100%;
  border-radius: 5px;
}

.userimage button {
  margin-left: 10px;
}

.profileimgage button {
  margin-left: 10px;
}

.portfolio .userinfo1 {
  color: #333;
  font-size: 20px;
  margin-top: 20px;
}

.portfolio .usersub {
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.row1 {
  margin-right: -25px;
  margin-left: -25px;
}

/* .feedscard .ant-card-body{
  padding: 0;
} */

.navbadge .ant-badge-count {
  z-index: auto;
  min-width: 15px;
  height: 15px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  white-space: nowrap;
  text-align: center;
  border-radius: 64px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
}

.navbadge .anticon {
  margin-right: 0;
  padding-top: 5px;
}

.ant-dropdown-menu {
  padding: 0;
}

.notifyhead {
  background-color: #1c41ce;
  margin: 0;
}

.notifytitle {
  color: #fff;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 0;
}

.badge:empty {
  display: inline-block;
}

.notifyhead .badge {
  background-color: #ffbf00;
}

.notimenulist {
  padding: 0;
  border-bottom: 1px solid #f5f5f5;
}

.allnotify {
  width: 100%;
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.notifflex {
  display: flex;
  border-left: 2px solid #ffbf00;
}

.notifybody {
  margin-bottom: 0;
  padding-right: 10px !important;
  font-size: 13px;
  /* box-shadow: 0 2px 2px #fff; */
}

.notifflex .ant-avatar {
  margin-top: 10px;
  margin-left: 10px;
}

.notifydate {
  margin-left: 5px;
  font-size: 12px;
}

.allnotify {
  color: #1c41ce;
  text-align: center;
}

.profilebg {
  margin: -24px -24px 13px;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.profilebg img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.profileimg {
  border-radius: 50%;
  display: block;
  width: 100px;
  height: 100px;
  cursor: pointer;
  object-fit: cover;
  margin: auto;
  position: relative;
  box-shadow: 2px 2px 6px #33333352;
}

.topdetail {
  margin-top: 30px;
}

.feedsuser li {
  padding: 10px;
  position: relative;
}

.feedsuser li .userbox {
  padding: 15px 15px 15px 15px;
  border: 1px solid #efefef;
  position: relative;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.feedsuser li .userbox:hover {
  -webkit-box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}

.feedsuser li .headerbox {
  height: 70px;
  background-color: #e3e9ff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.feedsuser li .userphoto .ant-avatar {
  margin-bottom: 15px;
}

.feedsuser li .userphoto .ant-avatar-image {
  background-color: #fff;
}

.feedsuser .userbox span.userinfo {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: #333 !important;
}

.feedsuser .ant-btn.follow {
  color: #1c41ce;
  background-color: #fff;
  border-color: #1c41ce;
  font-weight: 400;
}

.feedsuser .ant-btn.following {
  color: #fff;
  background-color: #1c41ce;
  border-color: #1c41ce;
  font-weight: 400;
  /* width: 82px; */
}

.feedsuser .ant-btn.userfollowing {
  color: #fff;
  background-color: #1c41ce;
  border-color: #1c41ce;
  font-weight: 400;
}

.viewall {
  font-size: 12px;
  color: #1c41ce;
}

.listmenu ul {
  list-style: none;
  padding: 0;
}

.listmenu li {
  text-align: left;
  color: #ccc !important;
  line-height: 20px;
  margin-bottom: 15px;
  padding: 5px;
  cursor: pointer;
}

.listmenu li:hover {
  background-color: #ccc3;
}

.listmenu a {
  color: #666;
  font-size: 14px;
  font-weight: 600;
}

.listmenu img {
  width: 30px;
  margin-right: 10px;
}

.linelement .anticon {
  color: #fff;
  font-size: 18px;
  padding-top: 15px;
}

.ant-menu-item .anticon {
  font-size: 18px;
  color: #333;
}

.seasonimg {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-top: 5px;
}

.spacereactors {
  display: inline-flex;
  /* margin-right: 20px; */
}

.margin {
  /* margin-right: 5px; */
  /* margin-left: 2px !important; */
}

.isliked {
  color: #31b347;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-flex;
  margin-right: 3px;
}

.likeicon {
  font-size: 30px;
  position: relative;
  top: 5px;
}

.isliked i {
  color: #fff;
  font-size: 14px;
  position: relative;
  left: 2px;
  top: 1px;
}

.isdisliked {
  display: inline-flex;
  color: #cc2132;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.isdisliked i {
  color: #fff;
  font-size: 14px;
  position: relative;
  left: 2px;
  top: 3px;
}

.reaclike {
  background: #31b347;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-flex;
  margin-right: 3px;
  position: absolute;
  top: 30px;
  left: 25px;
  z-index: 1;
}

.reaclike i {
  color: #fff;
  font-size: 15px;
  position: relative;
  left: 1px;
  top: 0px;
}

.reacdislike {
  background: #cc2132;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-flex;
  margin-right: 3px;
  position: absolute;
  top: 30px;
  left: 25px;
  z-index: 1;
}

.reacdislike i {
  color: #fff;
  font-size: 10px;
  position: relative;
  left: 3px;
  top: 2px;
}

.hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon:hover,
.hvr-icon:focus,
.hvr-icon:active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.feedstab .ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
  /* text-align: center; */
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  /* border-radius: 4px; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 2.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btntoggle,
.btntoggle:hover,
.btntoggle:focus {
  background-color: #fff;
  border-color: #fff;
  width: 30px;
  height: 30px;
}

.topdropdown .ant-dropdown {
  left: 205px !important;
  width: 100px !important;
}

.btntoggle i {
  color: #1c41ce;
  position: relative;
  right: 7px;
  top: 1px;
}

.postcreate {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.rightline {
  border-right: 1px solid #ddd;
}

.reactionsmodal {
  width: 350px !important;
  text-align: center;
}

.reacttab .ant-tabs-nav-wrap {
  text-align: center;
}

.gridlayout {
  padding: 0;
  background-color: #fff;
}

.slidetext {
  height: 600px;
  padding: 0;
}

.imgsliebanner {
  padding: 0;
  width: auto !important;
  max-height: 70vh !important;
  object-fit: cover;
}

.gridheader {
  text-align: left;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.gridtitle {
  position: absolute;
  top: 0;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  text-align: left;
}

.gridmodal .ant-modal-close-icon {
  box-shadow: 2px 2px 2px #ffffff24;
  color: #fff;
}

.gridmodal .ant-modal-body {
  padding: 10px;
  background-color: #fff;
}

.read-more-content {
  word-break: break-all;
}

.carousel.carousel-slider .control-arrow {
  top: 45%;
  color: #fff;
  font-size: 26px;
  bottom: auto;
  margin-top: 0;
  padding: 10px 8px;
  border-radius: 2px;
}

.carousel .control-next.control-arrow:before {
  border-left: 10px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 10px solid #fff !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  content: "";
}

.gridcontainer {
  position: relative;
  max-width: 100%;
  max-height: 210px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridimage {
  max-width: 100%;
  max-height: 200px;
  padding-bottom: 15px;
  object-fit: contain;
}

.networkform {
  padding: 30px 20px 10px;
  background: #fff;
}

.networkuserslist {
  background: #fff;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 30px;
}

.networkform h4,
.networkform p,
.networkform label {
  color: #333;
}

.networkform h4 {
  font-size: 28px;
  margin-bottom: 30px;
}

.networkform span {
  font-size: 14px;
}

.networkform p {
  margin-bottom: 30px;
}

.ant-slider:hover .ant-slider-track,
.ant-slider-track {
  background-color: #f8b63e;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #f8b63e;
}

.ant-slider-handle {
  border-color: #f8b63e !important;
}

.ant-slider-handle:focus {
  border-color: #f8b63e;
  outline: none;
  box-shadow: 0 0 0 5px rgba(244, 169, 51, 0.39);
}

.networkuserslist ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  margin-top: 15px;
}

.networkuserslist li {
  list-style-type: none;
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .networkuserslist li {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .networkuserslist li {
    flex: 100%;
    max-width: 100%;
  }
}

.networkuserslist li .userbox {
  background-color: #fff;
  /* margin-bottom: 15px; */
  padding: 24px;
}

.autocompletediv {
  position: relative;
}

.autoloading {
  position: absolute;
  top: 0px;
  width: 100%;
  background-color: #fff;
  line-height: 24px;
  padding: 5px;
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 34px;
  width: 100%;
  z-index: 10700;
  max-height: 250px;
  overflow-y: scroll;
}

.suggestion-item {
  line-height: 24px;
  padding: 5px 10px;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  line-height: 24px;
  padding: 5px 10px;
  color: #3b3a93;
  background-color: #f9f9f9;
  cursor: pointer;
}

.gm-style .gm-style-iw-c {
  background-color: white;
  border-radius: 2px;
  padding: 15px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.05);
  max-width: 200px !important;
  min-width: 150px !important;
  text-align: center;
}

.gm-style-iw-d .userphoto .ant-avatar {
  margin-bottom: 10px !important;
}

.mname {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.maddress {
  font-size: 12px;
  margin-bottom: 5px;
}

.mainformdiv {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 0.5s;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mainformfooter {
  display: none;
}

.mainformdiv.show .mainformfooter {
  display: block;
}

.mainformdiv.show {
  z-index: 101;
  position: relative;
}

.mainformbackdrop {
  /* transition: all 0.25s; */
}

.mainformbackdrop.show {
  background: #000;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s, bottom 0 0.3s;
  z-index: 100;
}

.formflex {
  display: block;
}

.formflex .ant-avatar {
  display: inline-block;
  vertical-align: top;
}

.formflex .ant-form-item {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: 10px;
  margin-bottom: 0;
  vertical-align: top;
}

.formflex textarea.ant-input {
  border: 0 !important;
  resize: none;
}

.mainformdiv.show .formflex {
  margin-bottom: 20px;
}

.ant-upload-picture-card-wrapper {
  padding: 15px;
}

.postformcount {
  display: inline-block;
  padding-left: 10px;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid #d9d9d9 !important;
}

.maindivshow {
  display: block;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 0 !important;
}

.maindivhide {
  display: none;
}

.profilewidget a {
  color: #333 !important;
}

.usersub a {
  color: #1c41ce !important;
}

.privacyterms {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  font-size: 16px;
  font-weight: 600;
}

.ant-collapse ul li {
  padding-bottom: 15px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.privacylist li {
  padding-bottom: 15px;
}

.ant-back-top {
  bottom: 50px;
  right: 50px;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgb(0 0 0 / 72%);
  filter: alpha(opacity=50);
}

.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1c41ce;
  color: #fff;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 6px #1c41ce9e;
}

.slidetext .ant-card-extra {
  float: right;
  margin-right: 15px;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  margin-left: 0;
}

.slidetext .ant-card-body {
  padding-left: 5px;
  padding-right: 15px;
  padding-top: 2px;
  zoom: 1;
}

.slidetext .captiontext {
  overflow-wrap: break-word;
  width: 100%;
  margin-left: -13px;
  padding: 5px 20px;
  text-align: left;
}

.slidetext .postalign {
  margin-left: -15px;
}

.carousel .carousel-status {
  position: absolute;
  top: 1px !important;
  bottom: 0;
  right: 25px;
  padding: 5px;
  font-size: 10px;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
}

.gridmodal .ant-modal-close-icon {
  box-shadow: none;
  color: #fff;
}

.ant-modal-close {
  right: -50px;
}

.ant-modal-close-x {
  font-size: 24px;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: transparent;
}

.carousel .slide .img1 {
  width: auto;
  vertical-align: top;
  border: 0;
  max-height: 600px !important;
}

.carousel .slide img {
  /* width: auto; */
  vertical-align: top;
  border: 0;
  /* max-height: 600px; */
}

.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgb(23 22 22 / 69%);
  background: #ffcb52;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.dripicons-location:before {
  content: "\e012";
  color: #333;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.arrowstyles {
  position: absolute;
  background-color: #1c41ce;
  color: #fff;
  padding: 0 5px !important;
  font-size: 16px !important;
  background-color: #333 !important;
  border: 1px solid #333 !important;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30;
  height: 30;
  cursor: "pointer";
}

.countrylabel {
  margin-bottom: 5px !important;
}

.countrydiv {
  margin-top: -12px;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 34px !important;
  padding-left: 115px !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 70px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 105px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -4px;
  left: 75px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #555;
}

/* chat */

.chatdiv {
  /* margin-bottom: 50px; */
  /* margin-top: -30px; */
  background-color: #ffffff;
  /* border: 1px solid #e8e8e8; */
}

.searchuser {
  padding: 15px;
  background-color: #ffffff;
}

.userlist ul {
  list-style-type: none;
  padding-left: 0;
}

.msgdiv {
  /* height: 61vh;
  overflow-y: scroll; */
  margin-bottom: 0px;
  margin-top: 10px;
}

.userlist ul li .chatuser {
  border: 1px solid #f5f5f5;
  border-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  /* margin-bottom: 5px; */
  border-left: 0;
  border-right: 0;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .msgdiv {
    margin-bottom: 0px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .userlist ul {
    display: flex;
    min-height: 55px;
    background-color: #f8f8f8;
    overflow-x: scroll;
  }

  .userlist ul li {
    border: 0px solid #f5f5f5;
    border-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-bottom: 5px; */
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    display: inline-block;
  }

  .userlist ul li .chatuser {
    border-right: 1px solid #f5f5f5 !important;
    border-bottom: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* margin-bottom: 5px; */
    border-left: 0;
    border-top: 0;
    cursor: pointer;
    display: inline-block;
    min-width: 60px;
  }

  .userlist ul li:last-child .chatuser {
    border-right: 1px solid #f5f5f5 !important;
    border-bottom: 0px solid #f5f5f5;
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* margin-bottom: 5px; */
    border-left: 0;
    border-top: 0;
    cursor: pointer;
  }

  .userlist ul li.active .chatuser {
    border-right: 1px solid transparent !important;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-bottom: 5px; */
    border-left: 0;
    border-top: 0;
    background-color: #e8eaf6;
    cursor: pointer;
  }

  .userlist .ant-avatar {
    height: 40px;
    width: 100%;
    margin-top: 5px !important;
    margin-left: 10px;
    max-width: 40px;
  }
}

.userlist ul li .chatuser {
  border: 1px solid #f5f5f5;
  border-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  /* margin-bottom: 5px; */
  border-left: 0;
  border-right: 0;
  cursor: pointer;
}

.userlist ul li:last-child .chatuser {
  border: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-left: 15px;
  padding-right: 15px;
  /* margin-bottom: 5px; */
  border-left: 0;
  border-right: 0;
  cursor: pointer;
}

.userlist ul li.active .chatuser {
  border: 1px solid transparent;
  padding-left: 15px;
  padding-right: 15px;
  /* margin-bottom: 5px; */
  border-left: 0;
  border-right: 0;
  background-color: #e8eaf6;
  cursor: pointer;
}

.userlist .chatusername {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 10px;
}

.padd15 {
  padding: 15px;
}

.userlist .chatusermsg {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userlist .ant-badge {
  position: absolute;
  right: 5px;
  top: 10px;
}

.userlist .ant-avatar {
  height: 40px;
  width: 100%;
  margin-top: 8px;
  max-width: 40px;
}

p.recentchats {
  padding: 10px 15px;
  margin-bottom: 0;
  margin-top: 10px;
  background-color: #f0f8ff52;
}

p.allchats {
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 0;
  background-color: #f0f8ff52;
}

.userlist .ant-avatar>img {
  /* width: 90% !important;
  height: 70% !important; */
  display: block;
  margin-top: 0px;
}

.userlist .nopaddl {
  padding-left: 0;
}

/* .chatuserlist {
  max-width: 280px;
} */

.userlist .ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  -ms-transform-origin: 0 center;
  transform-origin: 0 center;
  padding: 4px;
  font-size: 18px;
}

.chatrightbor {
  border-right: 1px solid #e8e8e8;
  padding: 0;
}

.messages {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}

.messages li.rightlist {
  justify-content: flex-end !important;
}

.startchat {
  text-align: center;
}

.messages li {
  width: 100%;
  display: flex;
  padding: 0;
}

.messages li.rightlist .ant-avatar {
  float: right;
}

.messages li .chatimg {
  max-height: 150px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.messages li .rightchat {
  float: right;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
  /* border: 1px solid #d8d8d8; */
  background-color: #e8eaf6;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  justify-content: flex-end !important;
  max-width: 300px;
}

.rightchat .senttime {
  font-size: 10px;
  /* margin-right: 20px; */
  margin-left: 0;
}

.messages .senttime {
  font-size: 10px;
  /* margin-left: 20px; */
  color: #9e9e9e;
  font-weight: 300;
}

.messages li .msgtext {
  color: #282828;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.messages li .leftchat .msgtext,
.messages li .leftchat .senttime {
  color: #fff;
}

.messages li .leftchat {
  color: #282828;
  margin-bottom: 10px;
  background-color: #1c41ce;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  max-width: 300px;
  position: relative;
}

.messages li .chatname {
  font-size: 12px;
  font-weight: 500;
  /* color: #949494; */
  color: #1890ff;
  margin-top: 0;
  margin-bottom: 5px !important;
}

/* .messages li .leftchat .msgtext,
.messages li .leftchat .senttime,
.messages li .leftchat .chatname {
  color: #fff;
} */
.ant-input-group-addon {
  text-align: left;
}

.messages li .rightchat {
  position: relative;
}

.downloadlink {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.msginput {
  padding: 15px 10px 10px;
  border-top: 1px solid transparent;
}

.msginput .ant-input-search {
  margin-bottom: 3px;
}

p.chatdate {
  text-align: center;
  background-color: aliceblue;
  padding: 2px 5px;
  border-radius: 2px;
  /* box-shadow: 0px 2px 4px 0px #00000026; */
  font-size: 12px;
}

.msginput .ant-input-search .ant-input-search-button {
  height: 40px;
  margin-bottom: 3px;
}

.msginput .ant-input-search input {
  height: 40px;
  margin-bottom: 3px;
}

.chatdiv .userinfo {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 10px;
}

.chatprofile .ant-avatar {
  margin-bottom: 20px;
  margin-top: 30px;
}

.chatprofile .activeusername {
  font-size: 15px;
  font-weight: 500;
}

.chatdiv .userinfo p {
  margin-bottom: 5px;
  margin-top: 0px;
  padding-left: 10px;
}

.userde {
  padding-left: 20px;
}

.chatdiv .userinfo p.username {
  display: inline-block;
  margin: 0 !important;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
}

.chatdiv .userinfo p.username span {
  font-size: 12px;
  font-weight: 400;
}

.userinfo .ant-avatar {
  display: inline-block;
}

.userchataction {
  padding: 10px;
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
  position: relative;
}

.userchataction .anticon {
  padding: 15px;
  cursor: pointer;
}

.userchataction .anticon:hover {
  background-color: #f0f2f5;
}

.userchataction .ant-input-affix-wrapper {
  /* display: none; */
  position: absolute;
  right: 15px;
  bottom: -32px;
  z-index: 1;
  box-shadow: 0px 5px 5px #f0f2f5;
}

.userchataction .ant-input-affix-wrapper .anticon {
  padding: 0;
}

.sharedphotos ul {
  list-style-type: none;
  padding-left: 0;
}

.sharedphotos li img {
  width: 100%;
  /* max-height: 70px; */
  margin-bottom: 10px;
  border-radius: 4px;
}

/* .sharedphotos li{
  background-color: #f5f5f5;
    border-radius: 5px;
    padding-bottom: 5px;
} */
.msginput .ant-form-item {
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.userlist li {
  padding: 0;
}

.messageempty {
  width: 100%;
  max-width: 250px;
  margin: auto;
}

.chatwrapper .ant-card-body {
  padding: 0;
}

.usershimmerwrapper {
  width: 100%;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 6px 6px #ebe7e745;
  border-radius: 2px;
}

.usershimmerwrapper .shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 300px;
  display: inline-block;
  position: relative;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.usershimmerwrapper .profile {
  height: 80px;
  width: 80px;
}

.usershimmerwrapper span {
  height: 10px;
  margin-top: 10px;
  width: 100%;
}

.usershimmerwrapper .text {
  display: inline-flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 10px;
  vertical-align: top;
  width: calc(100% - 100px);
}

.usershimmerwrapper .photo {
  display: block !important;
  width: 100%;
  height: 100px;
  margin-top: 15px;
}

.usershimmerwrapper .textline {
  display: inline-flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: -6px;
  vertical-align: top;
  width: calc(100% - 73px);
}

.gridshimmerwrapper {
  width: 100%;
  /* padding: 15px; */
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 6px 6px #ebe7e745;
  border-radius: 2px;
}

.gridshimmerwrapper .shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 300px;
  display: inline-block;
  position: relative;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.gridshimmerwrapper .photo {
  display: block !important;
  width: 100%;
  height: 180px;
  /* margin-top: 15px; */
}

.logshimmerwrapper {
  width: 100%;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 6px 6px #ebe7e745;
  border-radius: 2px;
}

.logshimmerwrapper .shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 300px;
  display: inline-block;
  position: relative;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.logshimmerwrapper .profile {
  height: 80px;
  width: 80px;
  padding: 15px;
  margin-left: 15px;
}

.logshimmerwrapper span {
  height: 10px;
  margin-top: 10px;
  width: 100%;
}

.logshimmerwrapper .text {
  display: inline-flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 0px;
  vertical-align: top;
  width: calc(100% - 125px);
}

.logshimmerwrapper .text1 {
  display: inline-flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 10px;
  vertical-align: top;
  width: calc(100% - 30px);
}

.logshimmerwrapper .photo {
  display: block !important;
  width: 100%;
  height: 150px;
}

.navbadge .anticon {
  color: #fff !important;
}

.picker-panel {
  z-index: 1 !important;
}

.picker-panel .footer {
  display: none !important;
}

.year-picker {
  width: 100% !important;
  height: 30px !important;
}

.searchwidth {
  width: 280px;
  position: relative;
  top: auto;
}

.liv-menu {
  width: 100%;
  margin-bottom: 15px;
}

.liv-menu ul {
  background-color: #fff;
  margin-bottom: 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
}

.liv-menu ul li {
  padding: 5px 10px;
  color: #212121;
  transition: all .25s ease-in;
}

.liv-menu ul li p {
  margin-bottom: 0px;
  padding: 5px 0px;
}

.liv-menu ul li p i {
  padding: 5px 10px;
  font-size: 16px;
}

.liv-menu ul li.active p i {
  font-size: 20px;
  transition: all .25s ease-in;
}

.liv-menu ul li:hover {
  background-color: #1c41ce12;
}

.liv-menu ul li:hover i {
  color: #1141e1;
}

.liv-menu ul li.active {
  background-color: #1c41ce12;
  color: #1141e1;
}

.profiledropdown {
  background-color: #fff;
  margin-bottom: 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  min-width: 200px;
}

.profiledropdown li {
  padding: 5px 10px;
  color: #212121;
  transition: all .25s ease-in;
}

.profiledropdown li p {
  margin-bottom: 0px;
  padding: 5px 0px;
}

.profiledropdown li p i {
  padding: 5px 10px;
  font-size: 16px;
}

.profiledropdown li:hover {
  background-color: #1c41ce12;
}

.profiledropdown li:hover i {
  color: #1141e1;
}

.profiledropdown li.prodrop-header {
  padding: 10px 10px;
}

.prodrop-header p {
  padding: 10px;
}

.feedpost-drop {
  background-color: #fff;
  margin-bottom: 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  min-width: 150px;
}

.feedpost-drop li {
  padding: 10px 10px;
  color: #212121;
  transition: all .25s ease-in;
  cursor: pointer;
}

.feedpost-drop li p {
  margin-bottom: 0px;
  padding: 5px 0px;
}

.feedpost-drop li p i {
  padding: 5px 10px;
  font-size: 16px;
}

.feedpost-drop li:hover {
  background-color: #1c41ce12;
}

.feedpost-drop li:hover i {
  color: #1141e1;
}

footer {
  padding: 15px;
  display: block;
  width: 100%;
  text-align: center;
  background-color: #fff;
  margin-top: 10px;
}

footer p {
  margin-bottom: 0;
}

/* notification */
.notilist {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  min-height: 300px;
}

.notilist li {
  border: 1px solid #f9f9f9 !important;
  margin-bottom: 0;
}

.notilist li.active {
  background-color: #1c41ce12;
  border: 1px solid #f9f9f9 !important;
  margin-bottom: 0;
}

.notilist .d-flex {
  display: flex;
  align-items: center;
}

.notilist .date {
  color: #212121;
  font-size: 12px;
  margin-bottom: 0;
  padding-left: 5px;
}

/* media query for mobile menu */
@media only screen and (max-width: 1023px) {
  .ant-layout-header {
    height: 52px;
    padding: 0 10px 0 15px;
    left: 0px;
    line-height: 50px;
    background: #1c41ce;
    box-shadow: 0 6px 12px 2px #00000020;
  }

  div .logo {
    width: 100px;
    float: left;
  }

  .searchwidth {
    width: 150px;
    position: absolute;
    top: 10px;
  }

  .menubutton {
    width: 30px;
    height: 30px;
  }

  .menutype {
    color: rgb(28, 65, 206) !important;
    font-size: 20px !important;
    position: relative;
    top: -11px;
    right: 9px;
  }

  .hidden-widget {
    display: none;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .drawerclose {
    float: right;
    margin: 15px;
    cursor: pointer;
  }

  .drawerlogout {
    float: left;
    margin: 15px;
    width: 30px;
    height: 30px;
  }

  .closeicon {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    color: #000 !important;
    right: 8px;
    top: 2px;
  }

  .logouticon {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    right: 8px;
    top: 2px;
  }

  .mobileuser {
    margin-top: 45px;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .mobileuserinfo {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    color: #1c41ce !important;
    padding-left: 10px;
  }
}

.profilelog .ant-card-body {
  padding: 0 !important;
}

.profileview {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.viewlayout {
  flex: 25%;
  max-width: 100%;
  padding: 0 4px;
}

.viewlayout1 {
  /* flex: 100%; */
  max-width: 100%;
  padding: 0 4px;
}

@media (max-width: 800px) {
  .profileview {
    flex-wrap: wrap;
  }

  .viewlayout {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .profileview {
    flex-wrap: wrap;
  }

  .viewlayout {
    flex: 100%;
    max-width: 100%;
  }
}

.blockuser,
.blockuser:hover,
.blockuser:focus {
  color: #161717 !important;
  background-color: #d1d7ef !important;
  border-color: #dadff1 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.verified {
  height: 20px;
  margin-left: 5px;
}

.feeds-verified {
  height: 15px;
  margin-left: 5px;
}

.blockmenu {
  font-size: 25px;
  color: rgb(51, 51, 51);
  margin-left: 10px;
  position: relative;
  top: 5px;
  font-weight: 600;
}

.blocktext {
  margin-bottom: -5px;
  margin-top: 8px;
}

.helpcenter header {
  display: none !important;
}

.helpcenter footer {
  display: none !important;
}

.helpcenter .titletext {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 30px;
  color: #fff;
}

.helpcenter .helpbg {
  background: #1c41ce;
  min-height: 240px;
}

.message .ant-btn.message-btn {
  padding: 1px 1px;
  background: #fff !important;
  color: #1c41ce !important;
  font-size: 18px;
  border: 1px solid #1c41ce !important;
}

.ant-btn-icon-only {
  width: 38px;
  height: 30px;
}

.postImage {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

/* offline styles */

.offline-card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
  top: 24px;
  right: 0;
  bottom: auto;
}

.offline-closable {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #272727;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}

.offline-message {
  display: inline-block;
  margin-bottom: 8px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.offline-description {
  font-size: 14px;
  color: #fff;
}

.offline-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #fff;
  outline: none;
}

.onlineClose {
  display: none;
}


.logincard-div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: auto;
  right: 0;
  bottom: 5px;
}

.logincard-closable {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #272727;
  -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}

.logincard-description {
  font-size: 14px;
  color: #fff;
}

.logincard-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #fff;
  outline: none;
}

.loginalert .ant-modal-close {
  right: 0 !important;
}

.loginalert .ant-modal-close-x {
  font-size: 14px !important;
}

.profilehoverbox {
  background-color: rgb(255, 255, 255);
  height: 150px;
  padding: 0;
  box-shadow: 2px 2px 4px #3333333d;
  border-radius: 4px;
}

.no-pad {
  padding: 0;
}